import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        @media (max-width: ${variables.mobileL}) {
            .training-targets-editor-header {
                flex-direction: column;
                align-content: flex-start;

                .training-targets-editor-header-col, .col-right {
                    margin-top: 1.5em;
                }
            }
        }
    `;