import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_DATA } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/adept/trainingTargets/Editor';

export default class AdeptTrainingTargetsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        trainingTarget: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;
        actions.single({ id: params.id });
    }

    render() {
        const { trainingTarget, location, history } = this.props;

        return (
            <StyledComponent
                className="adept-training-targets-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={trainingTarget}
                >
                    {trainingTarget && trainingTarget.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Zarządzaj celem treningowym"
                            controls={[{
                                visible: true,
                                key: 'return',
                                label: 'Powrót do celów treningowych',
                                onClick: () => history.push(
                                    withVariables(ADEPT_DATA.path, {}, { tab: 'trainingTargets' }),
                                ),
                                style: 'gradient',
                                layout: 'wide',
                            }]}
                            tabs={[{
                                key: 'trainingGoal',
                                label: 'Cel treningowy',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={trainingTarget.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </Page>
            </StyledComponent>
        );
    }
}