import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_TRAINING_TARGETS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/trainingTargets/Editor';
import { TAB_TRAINING_TARGETS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerTrainingTargetsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        trainingTarget: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;
        actions.single({ id: params.id });
    }

    render() {
        const { trainingTarget, location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-training-targets-manage"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={trainingTarget}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: (trainingTarget && trainingTarget.data && trainingTarget.data.user.id) }, { tab: TAB_TRAINING_TARGETS }),
                        label: 'Cele treningowe',
                    }, {
                        to: withVariables(TRAINER_TRAINING_TARGETS_MANAGE.path, { id: trainingTarget && trainingTarget.data && trainingTarget.data.id }),
                        label: `Cel treningowy adepta: ${(trainingTarget && trainingTarget.data && getFullName(trainingTarget.data.user, 'nameSurname').label)}`,
                    }]}
                >
                    {trainingTarget && trainingTarget.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Zarządzaj celem treningowym"
                            tabs={[{
                                key: 'trainingGoal',
                                label: 'Cel treningowy',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={trainingTarget.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}