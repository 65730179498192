import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
    .admin-element-editor {
        .editor-wrapper {
            .forms-wrapper {
                .form-wrapper {
                    .admin-form-generator {
                        .form-elements {
                            .panel-measurements-box {
                                .admin-panel-box {
                                    min-height: 18em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `;